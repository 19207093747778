import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Form, Button, Row, Col, Modal } from "react-bootstrap";

const MonHocManagement = () => {
  const [monHocs, setMonHocs] = useState([]);
  const [phongBans, setPhongBans] = useState([]);
  const [roles, setRoles] = useState([]);
  const [newMonHoc, setNewMonHoc] = useState({
    MaMonHoc: "",
    TenMonHoc: "",
    Video: "",
    ArrayPB: [],
    ArrayRole: [],
  });
  const [errors, setErrors] = useState({
    ArrayPB: false,
    ArrayRole: false,
  });

  // let [updateLink, setUpdateLink] = useState(true);

  useEffect(() => {
    fetchMonHocs();
    fetchPhongBans();
    fetchRoles();
  }, []);

  const fetchMonHocs = async () => {
    try {
      const response = await axios.get("https://server.congtien.com.vn/monhoc");
      setMonHocs(response.data);
    } catch (error) {
      console.error("Error fetching MonHocs:", error);
    }
  };

  const fetchPhongBans = async () => {
    try {
      const response = await axios.get(
        "https://server.congtien.com.vn/phongbans"
        // "http://192.168.1.106:30009/phongbans"
      );
      setPhongBans(response.data);
    } catch (error) {
      console.error("Error fetching PhongBans:", error);
    }
  };

  const fetchRoles = async () => {
    try {
      const response = await axios.get("https://server.congtien.com.vn/roles");
      setRoles(response.data);
    } catch (error) {
      console.error("Error fetching Roles:", error);
    }
  };

  const handleChange = (e) => {
    setNewMonHoc({ ...newMonHoc, [e.target.name]: e.target.value });
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditingMonHoc((prev) => ({
      ...prev,
      monHoc: {
        ...prev.monHoc,
        [name]: value,
      },
    }));
  };

  // let updateLink = false;

  const handleChangeLink = async (_id, _state) => {
    if (!_state) {
      const monHocUpdateData = {
        LinkMonHoc: "/ctdt/questionscomponent",
        trangthai: 1,
      }; // Update as needed
      await axios.put(
        `https://server.congtien.com.vn/monhoc/${_id}`,
        monHocUpdateData
      );
      alert("Đã hiện");
    } else {
      const monHocUpdateData = {
        LinkMonHoc: "/underdevelopment",
        trangthai: 0,
      }; // Update as needed
      await axios.put(
        `https://server.congtien.com.vn/monhoc/${_id}`,
        monHocUpdateData
      );

      alert("Đã Ẩn");
    }
    window.location.reload();
  };

  const handleCreateMonHoc = async (e) => {
    e.preventDefault();

    // Kiểm tra nếu ít nhất một checkbox được chọn trong mỗi nhóm
    const isArrayPBSelected = newMonHoc.ArrayPB.length > 0;
    const isArrayRoleSelected = newMonHoc.ArrayRole.length > 0;

    if (!isArrayPBSelected || !isArrayRoleSelected) {
      setErrors({
        ArrayPB: !isArrayPBSelected,
        ArrayRole: !isArrayRoleSelected,
      });
      alert("Vui lòng chọn ít nhất một phòng ban và vai trò.");
      return;
    }

    // Chuyển đổi định dạng ArrayPB và ArrayRole
    const formattedMonHoc = {
      ...newMonHoc,
      ArrayPB: newMonHoc.ArrayPB.map((pb) => ({ _idPhongBan: pb })),
      ArrayRole: newMonHoc.ArrayRole.map((role) => ({ _idRole: role })),
    };

    try {
      const response = await axios.post(
        "https://server.congtien.com.vn/monhoc",
        formattedMonHoc
      );
      setMonHocs([...monHocs, response.data]);
      alert("Thêm môn học mới thành công");
      setNewMonHoc({
        MaMonHoc: "",
        TenMonHoc: "",

        Video: "",
        ArrayPB: [],
        ArrayRole: [],
      });
      setErrors({
        ArrayPB: false,
        ArrayRole: false,
      });
    } catch (error) {
      console.error("Error creating MonHoc:", error);
    }
  };

  const handleDeleteMonHoc = async (_id) => {
    if (window.confirm("Bạn có chắc chắn muốn xóa môn học này không?")) {
      try {
        const response = await axios.get(
          `https://server.congtien.com.vn/check-monhoc/${_id}`
        );
        if (response.data.isUsed) {
          alert("Không thể xóa môn học này vì đang được sử dụng.");
          return;
        }

        await axios.delete(`https://server.congtien.com.vn/monhoc/${_id}`);
        setMonHocs(monHocs.filter((monHoc) => monHoc._id !== _id));
        alert("Xóa môn học thành công.");
      } catch (error) {
        console.error("Error deleting MonHoc:", error);
      }
    }
  };

  // Sửa
  const [showModal, setShowModal] = useState(false);
  const [editingMonHoc, setEditingMonHoc] = useState(null); // State lưu môn học đang chỉnh sửa

  const handleShow = (id) => fetchMonHocByID(id);
  const handleClose = () => setShowModal(false);

  const handleArrayChange = (e, arrayType) => {
    const { value, checked } = e.target;
    const updatedArray = checked
      ? [...newMonHoc[arrayType], value]
      : newMonHoc[arrayType].filter((item) => item !== value);
    setNewMonHoc({ ...newMonHoc, [arrayType]: updatedArray });
  };

  const handleEditArrayChange = (e, arrayType) => {
    const { value, checked } = e.target;
    setEditingMonHoc((prev) => {
      const currentArray = prev.monHoc[arrayType] || [];

      const newState = {
        ...prev,
        monHoc: {
          ...prev.monHoc,
          [arrayType]: checked
            ? [
                ...currentArray,
                {
                  [arrayType === "ArrayPB" ? "_idPhongBan" : "_idRole"]: value,
                },
              ]
            : currentArray.filter(
                (item) =>
                  item[arrayType === "ArrayPB" ? "_idPhongBan" : "_idRole"] !==
                  value
              ),
        },
      };
      return newState;
    });
  };

  const fetchMonHocByID = async (_id) => {
    try {
      const response = await axios.get(
        `https://server.congtien.com.vn/monhoc/${_id}`
      );

      if (response.data) {
        setEditingMonHoc(response.data);
        console.log(response.data);
        setShowModal(true); // Chỉ hiển thị modal sau khi có dữ liệu
      } else {
        console.log("Môn học không tồn tại hoặc không có dữ liệu.");
      }
    } catch (error) {
      console.error("Error fetching MonHoc by ID:", error);
    }
  };

  const handleQuestionChange = (index, value) => {
    const updatedQuestions = [...editingMonHoc.questions];
    updatedQuestions[index].DapAnDung = value;
    setEditingMonHoc({ ...editingMonHoc, questions: updatedQuestions });
  };

  const handleSaveChanges = async (idMonHoc) => {
    try {
      // Chuẩn bị dữ liệu cập nhật cho môn học
      const updatedMonHoc = {
        ...editingMonHoc.monHoc, // Lấy dữ liệu từ monHoc
        ArrayPB: Array.isArray(editingMonHoc.monHoc.ArrayPB)
          ? editingMonHoc.monHoc.ArrayPB // Đã đúng format, không cần map lại
          : [],
        ArrayRole: Array.isArray(editingMonHoc.monHoc.ArrayRole)
          ? editingMonHoc.monHoc.ArrayRole // Đã đúng format, không cần map lại
          : [],
      };
      // Gửi yêu cầu cập nhật môn học tới server
      const monHocResponse = await axios.put(
        `https://server.congtien.com.vn/monhoc/${idMonHoc}`,
        updatedMonHoc
      );

      // Kiểm tra kết quả cập nhật môn học
      if (monHocResponse.status === 200) {
        console.log("Cập nhật môn học thành công.");
      } else {
        alert("Cập nhật môn học không thành công. Vui lòng thử lại.");
        return;
      }

      // Cập nhật câu hỏi liên quan đến môn học
      for (let question of editingMonHoc.questions) {
        const updatedCauHoi = {
          NDCauHoi: question.NDCauHoi,
          A: question.A,
          B: question.B,
          C: question.C,
          D: question.D,
          DapAnDung: question.DapAnDung,
          // Thêm các trường khác nếu có
        };
        console.log(idMonHoc, question._id);

        // Gửi yêu cầu cập nhật từng câu hỏi tới server dựa trên idMonHoc và idCauHoi
        const cauHoiResponse = await axios.put(
          `https://server.congtien.com.vn/monhoc/${idMonHoc}/cauhoi/${question._id}`,
          updatedCauHoi
        );

        // Kiểm tra kết quả cập nhật câu hỏi
        if (cauHoiResponse.status === 200) {
          console.log(`Cập nhật câu hỏi ID: ${question._id} thành công.`);
        } else {
          alert(`Cập nhật câu hỏi ID: ${question._id} không thành công.`);
          return;
        }
      }

      // Nếu tất cả thành công, đóng modal và lấy lại danh sách môn học
      fetchMonHocs();
      setShowModal(false);
      alert("Cập nhật môn học và câu hỏi thành công.");
    } catch (error) {
      console.error("Lỗi khi cập nhật môn học và câu hỏi:", error);
      alert(
        "Có lỗi xảy ra khi cập nhật môn học hoặc câu hỏi. Vui lòng thử lại."
      );
    }
  };
  return (
    <div className="mt-4">
      <h2>Quản lý Môn Học</h2>
      <Form onSubmit={handleCreateMonHoc} className="mb-4 _form">
        <Form.Group className="_label" controlId="MaMonHoc">
          <Form.Label>Mã Môn Học</Form.Label>
          <Form.Control
            type="text"
            name="MaMonHoc"
            value={newMonHoc.MaMonHoc}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group className="_label mt-2" controlId="TenMonHoc">
          <Form.Label>Tên Môn Học</Form.Label>
          <Form.Control
            type="text"
            name="TenMonHoc"
            value={newMonHoc.TenMonHoc}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group className="_label mt-2" controlId="Video">
          <Form.Label>Video</Form.Label>
          <Form.Control
            type="text"
            name="Video"
            value={newMonHoc.Video}
            onChange={handleChange}
          />
        </Form.Group>

        {/* Phòng Ban */}
        <Form.Group className="_label mt-2" controlId="ArrayPB">
          <Form.Label>Chọn Phòng Ban</Form.Label>
          <Row className="_row ">
            {phongBans.map((pb, index) => (
              <Col className="mt-2 " sm={4} md={3} key={pb._id}>
                <Form.Check
                  className="text-capitalize fw-normal"
                  type="checkbox"
                  label={pb.TenPhongBan.toLowerCase()}
                  id={`phongBan-${index}`}
                  name="ArrayPB"
                  value={pb._id}
                  checked={newMonHoc.ArrayPB.includes(pb._id)}
                  onChange={(e) => handleArrayChange(e, "ArrayPB")}
                />
              </Col>
            ))}
          </Row>
          {errors.ArrayPB && (
            <p style={{ color: "red" }}>Vui lòng chọn ít nhất một phòng ban.</p>
          )}
        </Form.Group>

        {/* Vai Trò */}
        <Form.Group className="_label mt-2" controlId="ArrayRole">
          <Form.Label>Chọn Vai Trò</Form.Label>
          <Row className="_row ">
            {roles.map((role, index) => (
              <Col className="mt-2 fw-normal" sm={4} md={3} key={role._id}>
                <Form.Check
                  className="text-capitalize"
                  type="checkbox"
                  label={role.TenRole.toLowerCase()}
                  id={`role-${index}`}
                  name="ArrayRole"
                  value={role._id}
                  checked={newMonHoc.ArrayRole.includes(role._id)}
                  onChange={(e) => handleArrayChange(e, "ArrayRole")}
                />
              </Col>
            ))}
          </Row>
          {errors.ArrayRole && (
            <p style={{ color: "red" }}>Vui lòng chọn ít nhất một vai trò.</p>
          )}
        </Form.Group>

        <div>
          <Button variant="primary" type="submit" className="mt-3 mb-3">
            Thêm Môn Học
          </Button>
        </div>
      </Form>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>Mã Môn Học</th>
            <th>Tên Môn Học</th>
            <th className="text-center">Ẩn/Hiện</th>
            <th className="text-center">Actions</th>
          </tr>
        </thead>
        <tbody>
          {monHocs.map((monHoc, index) => (
            <tr key={monHoc._id}>
              <td>{index + 1}</td>
              <td>{monHoc.MaMonHoc}</td>
              <td>{monHoc.TenMonHoc}</td>
              <td className="text-center">
                {monHoc.trangthai === 1 && (
                  <Button
                    variant="dark"
                    id={`btn_${monHoc._id}`}
                    onClick={() => handleChangeLink(monHoc._id, true)}
                  >
                    Ẩn
                  </Button>
                )}
                {monHoc.trangthai === 0 && (
                  <Button
                    variant="success"
                    id={`btn_${monHoc._id}`}
                    onClick={() => handleChangeLink(monHoc._id, false)}
                  >
                    Hiện
                  </Button>
                )}
              </td>
              <td className="text-center">
                <Button
                  variant="warning"
                  onClick={() => handleShow(monHoc._id)}
                >
                  Sửa
                </Button>
                <Button
                  variant="danger"
                  className="mx-2"
                  onClick={() => handleDeleteMonHoc(monHoc._id)}
                >
                  Xóa
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal chỉnh sửa thông tin môn học */}
      <Modal
        centered
        size="xl" // Đây là kích thước rất lớn có sẵn
        aria-labelledby="example-custom-modal-styling-title"
        show={showModal}
        onHide={handleClose}
        className="my-2 z-99"
      >
        <Modal.Header closeButton>
          <Modal.Title>Sửa thông tin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {editingMonHoc && (
            <>
              <Form className="row flex-row">
                <Form.Group controlId="MaMonHoc" className="col-4">
                  <Form.Label>Mã Môn Học</Form.Label>
                  <Form.Control
                    type="text"
                    name="MaMonHoc"
                    value={editingMonHoc.monHoc.MaMonHoc}
                    onChange={handleEditChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mt-2 col-4" controlId="TenMonHoc">
                  <Form.Label>Tên Môn Học</Form.Label>
                  <Form.Control
                    type="text"
                    name="TenMonHoc"
                    value={editingMonHoc.monHoc.TenMonHoc}
                    onChange={handleEditChange}
                    required
                  />
                </Form.Group>
                <Form.Group className="mt-2 col-4" controlId="Video">
                  <Form.Label>Video</Form.Label>
                  <Form.Control
                    type="text"
                    name="Video"
                    value={editingMonHoc.monHoc.Video}
                    onChange={handleEditChange}
                  />
                </Form.Group>
                <Form.Group
                  className="mt-2 text-start text-capitalize lh-base border-bottom"
                  controlId="ArrayPB"
                >
                  <Form.Label>Chọn Phòng Ban</Form.Label>
                  <Row>
                    {phongBans.map((pb, index) => (
                      <Col sm={4} md={3} key={pb._id}>
                        <Form.Check
                          type="checkbox"
                          label={pb.TenPhongBan}
                          id={`phongBan-${index}`}
                          name="ArrayPB"
                          value={pb._id}
                          checked={
                            Array.isArray(editingMonHoc.monHoc.ArrayPB) &&
                            editingMonHoc.monHoc.ArrayPB.some(
                              (item) => item._idPhongBan === pb._id
                            )
                          }
                          onChange={(e) => handleEditArrayChange(e, "ArrayPB")}
                        />
                      </Col>
                    ))}
                  </Row>
                </Form.Group>
                <Form.Group
                  className="mt-2 text-start text-capitalize lh-base"
                  controlId="ArrayRole"
                >
                  <Form.Label>Chọn Vai Trò</Form.Label>
                  <Row>
                    {roles.map((role, index) => (
                      <Col sm={4} md={3} key={role._id}>
                        <Form.Check
                          type="checkbox"
                          label={role.TenRole.toLowerCase()}
                          id={`role-${index}`}
                          name="ArrayRole"
                          value={role._id}
                          checked={
                            Array.isArray(editingMonHoc.monHoc.ArrayRole) &&
                            editingMonHoc.monHoc.ArrayRole.some(
                              (item) => item._idRole === role._id
                            )
                          }
                          onChange={(e) =>
                            handleEditArrayChange(e, "ArrayRole")
                          }
                        />
                      </Col>
                    ))}
                  </Row>
                </Form.Group>
              </Form>
              <Form className="align-items-start text-start">
                {editingMonHoc.questions.map((item, index) => (
                  <div key={item._id} className="mb-3">
                    <h5>
                      Câu {index + 1}: {item.NDCauHoi}
                    </h5>
                    <div>
                      <Form.Check
                        type="radio"
                        label={`A: ${item.A}`}
                        id={`item-${index}-A`}
                        name={`question-${index}`}
                        value="A"
                        checked={item.DapAnDung === "A"}
                        onChange={() => handleQuestionChange(index, "A")}
                      />
                      <Form.Check
                        type="radio"
                        label={`B: ${item.B}`}
                        id={`item-${index}-B`}
                        name={`question-${index}`}
                        value="B"
                        checked={item.DapAnDung === "B"}
                        onChange={() => handleQuestionChange(index, "B")}
                      />
                      <Form.Check
                        type="radio"
                        label={`C: ${item.C}`}
                        id={`item-${index}-C`}
                        name={`question-${index}`}
                        value="C"
                        checked={item.DapAnDung === "C"}
                        onChange={() => handleQuestionChange(index, "C")}
                      />
                      <Form.Check
                        type="radio"
                        label={`D: ${item.D}`}
                        id={`item-${index}-D`}
                        name={`question-${index}`}
                        value="D"
                        checked={item.DapAnDung === "D"}
                        onChange={() => handleQuestionChange(index, "D")}
                      />
                    </div>
                  </div>
                ))}
              </Form>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Đóng
          </Button>
          <Button
            variant="primary"
            onClick={() => handleSaveChanges(editingMonHoc.monHoc._id)}
          >
            Lưu thay đổi
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MonHocManagement;
