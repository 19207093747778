import React, { useState, useCallback, useMemo } from "react";
import axios from "axios";
import "./QuestionsComponent.css";

const QuestionsComponent = ({ questions }) => {
  const [answers, setAnswers] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [score, setScore] = useState(0);
  const [showNotification, setShowNotification] = useState(false);

  const userData = useMemo(
    () => ({
      _idNV: localStorage.getItem("_idNV"),
      MaNV: localStorage.getItem("manv"),
      idmonhoc: localStorage.getItem("idmonhoc"),
    }),
    []
  );

  const shuffleArray = (array) => {
    const shuffled = [...array];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }
    return shuffled;
  };

  const shuffledQuestions = useMemo(() => shuffleArray(questions), [questions]);

  const handleAnswerChange = useCallback((questionId, answer) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (Object.keys(answers).length !== questions.length) {
      alert("Bạn phải trả lời tất cả các câu hỏi trước khi nộp bài.");
      return;
    }

    const { correctAnswers, ketQuaChiTiet } = questions.reduce(
      (acc, question) => {
        const isCorrect = answers[question._id] === question.DapAnDung;
        acc.correctAnswers += isCorrect ? 1 : 0;
        acc.ketQuaChiTiet.push({
          IDCauHoi: question._id,
          LuaChon: answers[question._id],
          Dung: isCorrect,
        });
        return acc;
      },
      { correctAnswers: 0, ketQuaChiTiet: [] }
    );

    const calculatedScore = (correctAnswers / questions.length) * 10;

    setScore(calculatedScore);
    setSubmitted(true);

    try {
      await axios.post("https://server.congtien.com.vn/ketqua", {
        _idMaNV: userData._idNV,
        Diem: calculatedScore,
        _idMonHoc: userData.idmonhoc,
        ketQuaChiTiet,
      });

      setShowNotification(true);
    } catch (error) {
      console.error("Lỗi khi lưu kết quả:", error);
      alert("Đã xảy ra lỗi khi lưu kết quả.");
    }

    setAnswers({});
  };

  const handleCloseNotification = useCallback(() => {
    setShowNotification(false);
    window.open("/ctdt", "_top");
  }, []);

  return (
    <div className="questions-form">
      <h2>Danh sách câu hỏi</h2>
      {shuffledQuestions.map((question) => (
        <div key={question._id} className="card mb-4">
          <div className="card-body">
            <p className="question-text">{question.NDCauHoi}</p>
            <div className="mt-3">
              {["A", "B", "C", "D"]
                .filter((option) => question[option])
                .map((option) => (
                  <div key={option} className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name={`question-${question._id}`}
                      id={`question-${question._id}-${option}`}
                      value={option}
                      onChange={() => handleAnswerChange(question._id, option)}
                      checked={answers[question._id] === option}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={`question-${question._id}-${option}`}
                    >
                      {question[option]}
                    </label>
                  </div>
                ))}
            </div>
          </div>
        </div>
      ))}
      <div className="input-maNV">
        <label>
          Mã nhân viên:
          <input
            disabled
            type="text"
            value={userData.MaNV}
            className="input-field"
          />
          <input type="hidden" value={userData._idNV} className="input-field" />
        </label>
      </div>
      <button type="submit" className="submit-button" onClick={handleSubmit}>
        Nộp bài
      </button>
      {submitted && showNotification && (
        <div className="notification-overlay">
          <div className="notification-box">
            <span>
              Bài làm của bạn đã được nộp. Điểm số của bạn là: {score}/10
            </span>
            <button className="close-button" onClick={handleCloseNotification}>
              &times;
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default QuestionsComponent;
