import React, { useState, useEffect, useCallback } from "react";
import "./Navbar.css";

const Navbar = ({ handleLogout }) => {
  const [scroll, setScroll] = useState(false);
  const [more, setMore] = useState(false);

  const tennv = localStorage.getItem("tennv");

  // Hàm throttle để giới hạn số lần gọi hàm
  const throttle = (func, limit) => {
    let inThrottle;
    return function () {
      const args = arguments;
      const context = this;
      if (!inThrottle) {
        func.apply(context, args);
        inThrottle = true;
        setTimeout(() => (inThrottle = false), limit);
      }
    };
  };

  const changeValueScroll = useCallback(
    throttle(() => {
      const scrollValue = window.scrollY;
      if (scrollValue > 100) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    }, 200),
    []
  );

  useEffect(() => {
    window.addEventListener("scroll", changeValueScroll);
    return () => window.removeEventListener("scroll", changeValueScroll);
  }, [changeValueScroll]);

  const handleMore = () => setMore(true);
  const handleCloseMore = () => setMore(false);

  return (
    <>
      <nav className={scroll ? "navbars mb-navbars" : "navbar mb-navbar"}>
        <div className="navbar-logo">
          <span className="span">CVTEC E-LEARNING</span>
          <span className="tennv">Xin chào: {tennv} </span>
        </div>

        <ul className="nav-links">
          <li>
            <a href="/">Trang chủ</a>
          </li>

          <li>
            <a href="/about">Về Chúng Tôi</a>
          </li>

          <li className="dropdown">
            <p>Lean</p>
            <div className="dropdown-content">
              <a className="nav-links-a alink" href="/ctdt">
                Chương Trình Đào Tạo
              </a>
              <a className="nav-links-a alink" href="/ct_tieuchuanhoa">
                Tiêu Chuẩn Môn học
              </a>
              <a className="nav-links-a alink" href="/ct_ketqua7llp">
                Kết quả Kỹ Năng Lean
              </a>
              <a className="nav-links-a alink" href="/ct_sukien">
                Sự Kiện
              </a>
            </div>
          </li>
          <li>
            <button className="Btn" onClick={handleLogout}>
              <div className="sign">
                <svg viewBox="0 0 512 512">
                  <path d="M377.9 105.9L500.7 228.7c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3L377.9 406.1c-6.4 6.4-15 9.9-24 9.9c-18.7 0-33.9-15.2-33.9-33.9l0-62.1-128 0c-17.7 0-32-14.3-32-32l0-64c0-17.7 14.3-32 32-32l128 0 0-62.1c0-18.7 15.2-33.9 33.9-33.9c9 0 17.6 3.6 24 9.9zM160 96L96 96c-17.7 0-32 14.3-32 32l0 256c0 17.7 14.3 32 32 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-64 0c-53 0-96-43-96-96L0 128C0 75 43 32 96 32l64 0c17.7 0 32 14.3 32 32s-14.3 32-32 32z"></path>
                </svg>
              </div>
              <div className="textbtn">Logout</div>
            </button>
          </li>
        </ul>

        <div className="mb-nav">
          <div className="mb-nav-item">
            <a href="/">
              <img
                width="50"
                height="50"
                src="https://i.imgur.com/sI9BH2f.png"
                alt="home"
              />
            </a>
          </div>
          <div className="mb-nav-item">
            <a href="/about">
              <img
                width="60"
                height="60"
                src="https://i.imgur.com/r2hWhu4.png"
                alt="about"
              />
            </a>
          </div>
          <div className="mb-nav-item">
            <img
              onClick={handleMore}
              width="60"
              height="60"
              src="https://i.imgur.com/lLxKaDZ.png"
              alt="menu-2"
            />
          </div>
          <div className="mb-nav-item">
            <p onClick={handleLogout}>
              <img
                width="50"
                height="50"
                src="https://i.imgur.com/Lrj4uNd.png"
                alt="exit"
              />
            </p>
          </div>
        </div>
      </nav>
      {more && (
        <div className="mb_more">
          <a href="/ctdt" className="mb_more_sub">
            Chương Trình đào tạo
          </a>
          <a href="ct_tieuchuanhoa" className="mb_more_sub">
            Tiêu chuẩn môn học
          </a>
          <a href="/ctdt/ct_ketqua7llp" className="mb_more_sub">
            Kết quả kỹ năng Lean
          </a>
          <a href="/ctdt/ct_sukien" className="mb_more_sub">
            Sự kiện
          </a>
          <button className="close-more" onClick={handleCloseMore}>
            <svg
              height="16"
              width="16"
              xmlns="http://www.w3.org/2000/svg"
              version="1.1"
              viewBox="0 0 1024 1024"
            >
              <path d="M874.690416 495.52477c0 11.2973-9.168824 20.466124-20.466124 20.466124l-604.773963 0 188.083679 188.083679c7.992021 7.992021 7.992021 20.947078 0 28.939099-4.001127 3.990894-9.240455 5.996574-14.46955 5.996574-5.239328 0-10.478655-1.995447-14.479783-5.996574l-223.00912-223.00912c-3.837398-3.837398-5.996574-9.046027-5.996574-14.46955 0-5.433756 2.159176-10.632151 5.996574-14.46955l223.019353-223.029586c7.992021-7.992021 20.957311-7.992021 28.949332 0 7.992021 8.002254 7.992021 20.957311 0 28.949332l-188.073446 188.073446 604.753497 0C865.521592 475.058646 874.690416 484.217237 874.690416 495.52477z"></path>
            </svg>
            <span>Back</span>
          </button>
        </div>
      )}
    </>
  );
};

export default Navbar;
